<template>
  <v-container>
    <div>
      <h2>
        <v-icon color=#3a4f3f>
          mdi-bell-ring
        </v-icon>
        알림 설정
      </h2>
      <h4>축사 별 기준 센서 및 알림 온도를 설정합니다.</h4>
    </div>

    <div class="select__container">
      <span class="label">
        <v-icon color="#3a4f3f">
          mdi-home-modern
        </v-icon>
        농장
      </span>

      <v-select dense outlined rounded class="selectbox"
          id="farm" 
          ref="farm" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
        />

        <v-btn class="normal_btn ml-2 mt-1" @click="refresh()">조회</v-btn>
    </div>

    <div class="alertSet__container">
      <div class="gab__container" v-for="(item,i) in items" :key="i">
        <span class="building__name">
          <img :src="farm_icon" class="farm_icon">
          {{ item.building.name }}
        </span>

          <div class="alert__container">
            <div class="setting">
              <div class="value__container">
                <span class="mt-1 mr-3">
                  <v-icon color="#3a4f3f">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  내・외부 온도 격차 알림
                </span>
                <v-switch
                  v-model="item.room_outside_yn"
                  hide-details
                  class="switch mt-0 ml-1"
                  color="#3a4f3f"
                  inset
                ></v-switch>
              </div> 
              <div class="explain"> 기준 외부 센서의 위치 및 알림 받을 격차 값을 설정하세요. </div>

              <div class="value__container roomSet" v-if="item.room_outside_yn">
                <v-select dense outlined rounded
                  class="roomSelect ma-1"
                  id="base_room" 
                  ref="base_room" 
                  label="기준돈방" 
                  v-model="item.base_room"
                  :items="roomItems"
                  :menu-props="{ top: false, offsetY: true }"
                  no-data-text="자료(권한)이 없습니다."
                  item-text="name"
                  @change="changeRoom(i)"
                  return-object
                />

                <v-text-field dense outlined rounded 
                  type="number"
                  id="room_outside_gap"
                  ref="room_outside_gap"
                  class="setting_textbox shrink ma-1"
                  label="온도차"
                  min="1"
                  max="20"
                  :disabled="item.room_outside_yn == false"
                  v-model="item.room_outside_gap"/>
              </div>
            </div>

            <div class="setting">
              <div class="value__container">
                <span class="mt-1 mr-3">
                  <v-icon color="#3a4f3f">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  내부 온도변화 알림
                </span>
                <v-switch
                  v-model="item.room_changeup_yn"
                  hide-details
                  class="mt-0 ml-1"
                  color="#3a4f3f"
                  inset
                ></v-switch>
              </div>

              <div class="explain"> 알림받을 상한,하한 값을 설정하세요.(+ 급격한 온도변화 발생 시 알림) </div>

              <div class="value__container roomSet" v-if="item.room_changeup_yn">

                <v-text-field dense outlined rounded
                    type="number"
                    id="room_changeup_min"
                    ref="room_changeup_min"
                    class="setting_textbox centered-input shrink ma-1"
                    label="하한온도"
                    min="0"
                    max="30"
                    :disabled="item.room_changeup_yn == false"
                    v-model="item.room_changeup_min"/>

                <v-text-field dense outlined rounded
                    type="number"
                    id="room_changeup_max"
                    ref="room_changeup_max"
                    class="setting_textbox centered-input shrink ma-1"
                    label="상한온도"
                    min="0"
                    max="30"
                    :disabled="item.room_changeup_yn == false"
                    v-model="item.room_changeup_max"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btn__container">
        <v-btn @click=save() class="save_btn">저장</v-btn>
      </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";

export default {
  name: "AlertSet",
  data: () => ({
    building: require("@/assets/monitoring/building.svg"),
    farm_icon: require("@/assets/monitoring/farm.svg"),

    farm : {},
    farmItems : [],
    roomItems : [],

    items:[],
  }),
  
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboFarms();
    await this.comboRooms();

    let cookie_info = VueCookies.get("alertSet_info"); // 이전 조회정보를 참조

    if (cookie_info) {
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
    } else {  // 이전 조회정보가 없을 경우 첫번째 농장/사업장 디폴트로 참조
        this.farm = this.farmItems[0];
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
    }
    this.refresh();
  },

  methods:{
    refresh(){
      this.$store.commit("resMessage","");
      this.loading = true;

      Apis.listAlertSetupOfFarm({
        farm_cd: this.farm && this.farm.code,
        } ,(res) => {  // 정상처리
          this.items = res.data;
          this.comboRooms();
          // console.log('item',this.items);

          let cookie_info = {company: this.company, farm: this.farm, building: this.building };
          VueCookies.set("alertSet_info" ,cookie_info,"30d");

        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("listAlertSetupOfFarm 호출 오류",err);

          this.$router.push("/");
          this.loading = false;
        })
    },
    checkData(){
      // console.log(this.items)
      for (let i in this.items) {
        if (this.items[i].room_outside_yn) {
          if (!this.items[i].base_room.code) {
            this.$refs.base_room[i].focus();
            return "기준이 되는 외부센서의 위치와 알림받을 격차 값을 설정하세요.";
          }
          if (!Common.isNumeric(this.items[i].room_outside_gap) ) {
            this.$refs.room_outside_gap[i].focus();
            return "기준이 되는 외부센서의 알림받을 격차 값을 설정하세요.";
          }
          if (this.items[i].room_outside_gap < 1 ) {
            this.$refs.room_outside_gap[i].focus();
            return "양수를 입력해주세요.";
          }
        }

        if (this.items[i].room_changeup_yn) {
          if (this.items[i].room_changeup_min < -50 ) {
            this.$refs.room_changeup_min[i].focus();
            return "정확한 값을 입력해주세요.";
          }
          if (this.items[i].room_changeup_max < -50 ) {
            this.$refs.room_changeup_max[i].focus();
            return "정확한 값을 입력해주세요.";
          }
          if (!Common.isNumeric(this.items[i].room_changeup_min) ) {
            this.$refs.room_changeup_min[i].focus();
            return "하한온도 값을 입력하세요.";
          }
          if (!Common.isNumeric(this.items[i].room_changeup_max) ) {
            this.$refs.room_changeup_max[i].focus();
            return "상한온도 값을 입력하세요.";
          }
          if (this.items[i].room_changeup_min > this.items[i].room_changeup_max ) {
            this.$refs.room_changeup_min[i].focus();
            return "상한온도보다 하한온도 값은 낮게 입력하세요.";
          }
        }
      }
    },
    save(){
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      // this.loadingSave = true;
      Apis.saveAlertSetupOfFarm({
      "buildings" : this.items,
      },(res) => {
        if (res.result) {
          this.$store.commit("resMessage",res.message);
          this.loadingSave = false;
          // this.$emit("refresh");
            // this.infoDeodorizerSetting(this.id);
          // this.$router.go(-1);
        } else {
          this.loadingSave = false;
          console.log("저장 오류",res.message)
        }
      }).catch( (err) => {  // API 오류 처리
          this.loadingSave = false;
          console.log("saveAlertSetupOfFarms API 호출 오류",err)
          alert(err);
      })
  
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            // console.log(this.farmItems)
            
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            // alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.building = {};
      }
      this.buildingItems = [];
      this.refresh();
    },

    async comboRooms() {
      await Apis.comboRooms({
        company_cd: "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: "",
        name_flag: true,
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            // console.log(res.data)

            for (let i in res.data) {
            this.roomItems.push({
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      this.base_room = {code:this.room_cd, name:this.room_name}
    },

  }
}
</script>

<style lang="scss" scoped>
h3{
  letter-spacing: -1px;
  color:#3a4f3f;
}
.select__container{
  display:flex;
  margin-top:40px;  
  width:100%;
  margin-left:10px;
}
.label{
  width:70px;
  margin-top:10px;
  font-size:18px;
  font-weight:700;
  color:#3a4f3f;
  // margin-rightpx;
}
.selectbox{
  width:50%;
  max-width:260px !important;
}
.normal_btn{
  border-radius: 30px;
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.gab__container{
  border:1px solid #3a4f3f;
  background-color:#FCF9F0;
  margin:10px 10px 30px 10px;
  padding:10px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}
.building__name{
  position:relative;
  top:-20px;
  background-color:#fbf2d5;
  color:#3a4f3f;
  font-size:20px;
  font-weight:700;
  letter-spacing: -1px;
  border-radius: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  padding:10px;
}
.Set__container, .alert__container, .value__container{
  display:flex;
}
.value__container > span{
  font-size:18px;
  font-weight: 700;
  color:#3a4f3f;
  letter-spacing: -1px;
  // text-shadow: 1px 0px 0px gray; 
}
.explain{
  font-size:15px;
  letter-spacing: -0.74px;
  color:#767676;
  margin-bottom: 10px;
  margin-left:10px;
}
.roomSelect{
  max-width:300px;
}
.setting_textbox{
  min-width:190px;
  width:50%;
}
.roomSelect, .setting_textbox{
  height:40px !important;
}

.setting{
  background-color:white;
  border:1px solid #3a4f3f;
  border-radius:10px;
  margin:10px;
  padding:10px;
  width:50%;
}
.btn__container{
  text-align: center;
}
.save_btn{
  width:90px !important;
  height:50px !important;
  font-size:18px;
  font-weight: 600;
  background-color:#3a4f3f !important;
  color:white;
  letter-spacing: -1px;
  border-radius: 30px !important;
}
.farm_icon{
  width:30px;
  height:30px;
  padding-top:10px;
}
@media screen and (max-width: 768px){
  .Set__container, .alert__container{
    display:block;
  }
  .roomSet{
    flex-direction: column; 
  }
  .setting_textbox{
    // min-width:200px;
    max-width:290px;
    width:100%;
  }
  .setting{
    margin:0px;
    margin-bottom: 10px;
    padding:5px;
    width:100%;
  }
  
}

</style>